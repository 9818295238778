import { FC } from "react";
import Link from "next/link";
import { Svg } from "components/base/svg/Svg";
import Translate from "components/base/translate/Translate";
import styles from "./sub-header.module.sass";

const SubHeader: FC = () => {
    return (
        <div className={styles.subHeader}>
            <nav>
                <Link href="#">
                    <Svg
                        src="/common/mobile/base-icons/menu-sport.svg"
                        wrapper="span"
                        className="svg-icon"
                    />
                    <Translate tid="pageName_sport" />
                </Link>
                <Link href="#" className={styles.active}>
                    <Svg
                        src="/common/mobile/base-icons/menu-live.svg"
                        wrapper="span"
                        className="svg-icon"
                    />
                    <Translate tid="pageName_live" />
                </Link>
                <Link href="#">
                    <Svg
                        src="/common/mobile/base-icons/menu-casino.svg"
                        wrapper="span"
                        className="svg-icon"
                    />
                    <Translate tid="pageName_livecasino" />
                </Link>
                <Link href="#">
                    <Svg
                        src="/common/mobile/base-icons/submenu-aviator.svg"
                        wrapper="span"
                        className="svg-icon"
                    />
                    Aviator
                </Link>
                <Link href="#">
                    <Svg
                        src="/common/mobile/base-icons/submenu-promotions.svg"
                        wrapper="span"
                        className="svg-icon"
                    />
                    Promotions
                </Link>
            </nav>
        </div>
    );
};

export default SubHeader;
