import {
    MINUTE_IN_MS,
    formatAppNameText,
    WalletLayoutEnum,
    ISiteConfigLayouts,
} from "@finbackoffice/fe-core";
import {
    AuthContext,
    ConfigContext,
    CpfSignupProvider,
    ModalsContext,
    PopupBannersContext,
    SiteNotificationsContext,
    UserAccountContext,
    useDocumentVisible,
    useRuntimeConfig,
    useSiteConfig,
} from "@finbackoffice/site-core";
import classnames from "classnames";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, RefObject, useCallback, useContext, useEffect, useMemo, useRef } from "react";
import Button from "components/base/button/Button";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import Img from "components/base/img/Img";
import Loading from "components/base/loading/Loading";
import Modal, { IModalForwardRefProps } from "components/base/modal/Modal";
import { Svg } from "components/base/svg/Svg";
import Translate from "components/base/translate/Translate";
import { InitialDataContext } from "contexts";
import { roboto } from "fonts/roboto";
import { useLatestExchangeRates } from "hooks/useLatestExchangeRates";
import { ModalTypes, RouterQuery } from "utils/constants";
import PreloadComponent from "components/base/preload-component/PreloadComponent";
import { IWalletProps } from "components/base/wallet/wowkorea-wallet/WowkoreaWalletWidget";
import { PopupBanners } from "./popup-banners/PopupBanners";
import SiteNotifications from "./site-notifications/SiteNotifications";
import SignupModal from "./SignupModal";
import WalletSelector from "./wallet/WalletSelector";
import styles from "./header.module.sass";
import SubHeader from "./sub-header/SubHeader";

const Settings = dynamic(() => import("./settings/Settings"), {
    loading: () => <PreloadComponent style={{ margin: 0, border: 0 }} />,
    ssr: false,
});

const WalletWidgetAsync = dynamic<IWalletProps>(
    () =>
        import("components/base/wallet/wowkorea-wallet/WowkoreaWalletWidget").then(
            (module) => module.WalletWidget,
        ),
    {
        loading: () => <Loading />,
        ssr: false,
    },
);

const PixWalletWidget = dynamic(
    () =>
        import("components/base/wallet/pix-wallet/PixWalletWidget").then(
            (module) => module.PixWalletWidget,
        ),
    {
        loading: () => <Loading />,
        ssr: false,
    },
);

const TolaWalletWidget = dynamic(
    () =>
        import("components/base/wallet/tola-wallet/TolaWalletWidget").then(
            (module) => module.TolaWalletWidget,
        ),
    {
        loading: () => <Loading />,
        ssr: false,
    },
);

const Header: FC = () => {
    const { hasPopups, popups } = useContext(PopupBannersContext);
    const { hasNotifications, notifications } = useContext(SiteNotificationsContext);
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const siteLayoutsConfig = useSiteConfig<ISiteConfigLayouts>("layouts");
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const router = useRouter();
    const { setSiteHeaderHeight } = useContext(InitialDataContext);
    const { loadProfile, logout } = useContext(UserAccountContext);
    const {
        currentModal,
        setCurrentModal,
        loginModalRef,
        signupModalRef,
        openWalletModal,
        walletModalRef,
    } = useContext(ModalsContext);
    const { siteConfigs } = useContext(ConfigContext);
    const { isUserLoggedIn } = useContext(AuthContext);
    const settingsModalRef: RefObject<IModalForwardRefProps> = useRef(null);

    const inactivityModalRef: RefObject<IModalForwardRefProps> = useRef(null);
    const headerRef = useRef<HTMLElement>(null);
    const isTabActive = useDocumentVisible(
        COMMON_SITE_CONFIGS.detectInactivity.enable && isUserLoggedIn,
    );
    const inactivityTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    useLatestExchangeRates();

    useEffect(() => {
        if (headerRef.current?.offsetHeight) {
            setSiteHeaderHeight(headerRef.current?.offsetHeight);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (router.query?.type === RouterQuery.Signup && signupModalRef.current) {
            signupModalRef.current.open();
        } else if (router.query?.type === RouterQuery.Wallet && walletModalRef.current) {
            walletModalRef.current.open();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query?.type]);

    useEffect(() => {
        if (COMMON_SITE_CONFIGS.detectInactivity.enable) {
            if (!isTabActive) {
                inactivityTimeoutRef.current = setTimeout(async () => {
                    logout();

                    inactivityModalRef.current?.open();
                }, COMMON_SITE_CONFIGS.detectInactivity.period * MINUTE_IN_MS);
            } else {
                if (inactivityTimeoutRef.current) {
                    clearTimeout(inactivityTimeoutRef.current);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTabActive]);

    const onSignupPress = () => {
        if (currentModal === ModalTypes.SIGNUP) {
            setCurrentModal(null);
        } else {
            signupModalRef.current?.open();
        }
    };

    const onWalletOpen = () => {
        if (currentModal !== ModalTypes.WALLET) {
            openWalletModal();
        }
    };

    const onWalletClose = useCallback(async () => {
        await loadProfile();
        setCurrentModal(null);
    }, [loadProfile, setCurrentModal]);

    const onSettingsButtonClick = useCallback(() => {
        if (currentModal === ModalTypes.SETTINGS) {
            setCurrentModal(null);
        } else {
            settingsModalRef.current?.open();
        }
    }, [currentModal, setCurrentModal]);

    const closeInactivityModal = useCallback(() => {
        inactivityModalRef.current?.close();
    }, []);

    const renderInactivityModal = useMemo(
        () => (
            <>
                <p>
                    <Translate
                        tid="inactivity_text"
                        replace={{ period: COMMON_SITE_CONFIGS.detectInactivity.period }}
                    />
                </p>
                <div>
                    <Button type="button" variant="secondary" onClick={closeInactivityModal}>
                        <Translate tid="base_ok" />
                    </Button>
                    <Button
                        type="button"
                        variant="primary"
                        onClick={() => {
                            loginModalRef.current?.open();
                        }}>
                        <Translate tid="header_login" />
                    </Button>
                </div>
            </>
        ),
        [COMMON_SITE_CONFIGS.detectInactivity.period, closeInactivityModal, loginModalRef],
    );

    const renderWallet = useMemo(() => {
        switch (COMMON_SITE_CONFIGS.wallet.type) {
            case WalletLayoutEnum.Pix:
                return (
                    <ErrorBoundary name={PixWalletWidget.name}>
                        <Modal
                            ref={walletModalRef}
                            styleClass={classnames(styles.walletPixModal, roboto.variable)}
                            type={ModalTypes.WALLET}
                            animateVariant="opacity"
                            maskClosable={false}
                            closeButton={
                                <Svg
                                    src="/common/mobile/base-icons/close.svg"
                                    wrapper="span"
                                    onClick={onWalletClose}
                                    className={styles.closeWalletPix}
                                    data-testid="wallet-modal-close"
                                />
                            }>
                            <PixWalletWidget />
                        </Modal>
                    </ErrorBoundary>
                );
            case WalletLayoutEnum.Tola:
                return (
                    <ErrorBoundary name={TolaWalletWidget.name}>
                        <Modal
                            ref={walletModalRef}
                            styleClass={classnames(styles.walletTolaModal, roboto.variable)}
                            closeButton
                            type={ModalTypes.WALLET}
                            animateVariant="opacity"
                            maskClosable={false}>
                            <TolaWalletWidget />
                        </Modal>
                    </ErrorBoundary>
                );
            default:
                return (
                    <ErrorBoundary name={WalletWidgetAsync.name}>
                        <Modal
                            ref={walletModalRef}
                            styleClass={classnames(styles.walletModal, roboto.variable)}
                            type={ModalTypes.WALLET}
                            animateVariant="rightFullPage"
                            onClose={onWalletClose}
                            closeButton={
                                <Button
                                    type="button"
                                    onClick={onWalletClose}
                                    className={styles.walletButtonActive}
                                    data-testid="wallet-modal-close">
                                    <span>
                                        <Translate tid="header_wallet" />
                                    </span>
                                    <Svg
                                        src="/common/desktop/base-icons/close-white.svg"
                                        wrapper="span"
                                        className="svg-icon"
                                    />
                                </Button>
                            }>
                            <div className={styles.walletModalContainer}>
                                <WalletWidgetAsync onClose={onWalletClose} />
                            </div>
                        </Modal>
                    </ErrorBoundary>
                );
        }
    }, [COMMON_SITE_CONFIGS.wallet.type, onWalletClose, walletModalRef]);

    return (
        <ErrorBoundary name={Header.name}>
            <header className={styles.header} ref={headerRef}>
                <Button
                    data-testid="settings-button"
                    type="button"
                    className={`${styles.collapseBtn} ${
                        currentModal === ModalTypes.SETTINGS && styles.opened
                    }`}
                    onClick={onSettingsButtonClick}>
                    <span />
                    <span />
                    <span />
                </Button>
                <Link href="/">
                    <Img
                        source={`${ASSETS_URL}/${formatAppNameText(
                            COMMON_SITE_CONFIGS.appName,
                        )}/mobile/logo.svg`}
                        alt={`${COMMON_SITE_CONFIGS.appName} logo`}
                        title={`${COMMON_SITE_CONFIGS.appName} logo`}
                        width={0}
                        height={0}
                        style={{ width: "100%", height: "auto" }}
                    />
                </Link>

                <ErrorBoundary name={WalletSelector.name}>
                    <WalletSelector />
                </ErrorBoundary>
                {isUserLoggedIn ? (
                    <>
                        <Button
                            type="button"
                            onClick={onWalletOpen}
                            className={styles.walletButton}
                            data-testid="open-wallet-button">
                            <Svg
                                src="/common/mobile/base-icons/wallet.svg"
                                wrapper="span"
                                className={classnames("svg-icon", styles.walletIcon)}
                            />
                        </Button>
                        {siteConfigs && renderWallet}
                    </>
                ) : (
                    <>
                        <Button
                            data-testid="signupButton"
                            type="button"
                            onClick={onSignupPress}
                            variant="primary"
                            className={styles.registerBtn}>
                            <Translate tid="header_registerNow" />
                        </Button>
                        <CpfSignupProvider>
                            <SignupModal />
                        </CpfSignupProvider>
                    </>
                )}
                <ErrorBoundary name={Settings.name}>
                    <Modal ref={settingsModalRef} type={ModalTypes.SETTINGS} animateVariant="left">
                        <Settings />
                    </Modal>
                </ErrorBoundary>

                {hasNotifications && (
                    <ErrorBoundary name={SiteNotifications.name}>
                        <SiteNotifications notifications={notifications} />
                    </ErrorBoundary>
                )}

                {hasPopups && (
                    <ErrorBoundary name={PopupBanners.name}>
                        <PopupBanners banners={popups} />
                    </ErrorBoundary>
                )}

                {COMMON_SITE_CONFIGS.detectInactivity.enable && (
                    <Modal
                        ref={inactivityModalRef}
                        styleClass={styles.inactivityModal}
                        type={ModalTypes.INACTIVITY}
                        animateVariant="opacity">
                        {renderInactivityModal}
                    </Modal>
                )}
            </header>
            {siteLayoutsConfig.subHeader.enable && <SubHeader />}
        </ErrorBoundary>
    );
};

export default Header;
